<template>
  <div class="page">
    <div class="page_title">{{ $t('paymentPage.title') }}</div>
    <div class="content">
      <div class="item" v-for="(item, index) in $t('paymentPage.list')" :key="index">
        <div class="title">{{ item.title }}</div>
        <ul>
          <li v-for="(it, i) in item.list" :key="i">{{ it }}</li>
        </ul>
      </div>
    </div>
    <div>
      <div class="info">
        <div class="star" />
        <div class="text">
          <div>{{ $t('paymentPage.info1') }}</div>
          <div>{{ $t('paymentPage.info2') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'payment',
  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
@mixin pixel-ratio() {
  @media screen and (-webkit-min-device-pixel-ratio: 1),
  screen and (min--moz-device-pixel-ratio: 1) {
    @import '../assets/scss/sprite/payment.scss';
    .star {
      @include sprite($star);
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min--moz-device-pixel-ratio: 2) {
    @import '../assets/scss/sprite/payment@2x.scss';
    .star {
      @include sprite($star-2x);
    }
  }
}

.page {
  @include pixel-ratio();
  text-align: center;
  &_title {
    padding: 40px 0 70px 0;
    font-size: 24px;
    color: #252525;
  }
  .content {
    margin: 0 auto;
    display: inline-block;
    .item {
      text-align: left;
      padding-bottom: 40px;
      .title {
        font-size: 18px;
        color: #252525;
      }
      ul li {
        line-height: 24px;
        list-style: none;
        color: #434343;
      }
    }
  }
  .info {
    display: inline-block;
    margin: 0 auto;
    margin-top: 40px;
    border: 2px dashed #CDCDCD;
    padding: 25px 40px;
    margin-bottom: 30px;
    .star {
      display: inline-block;
      margin-right: 40px;
      vertical-align: super;
    }
    .text {
      display: inline-block;
      color: #252525;
      font-size: 18px;
      text-align: left;
    }
  }
}
</style>
